import React, {FC, useEffect, useState} from "react";
import * as Yup from "yup";
import {SocialMeta} from "../../layout/base/social-meta";
import {t} from "i18next";
import {useFormik} from "formik";
import {clsx} from "clsx";
import Select from "react-select";
import {contactUs, getCountries} from "./core/requests";
import 'react-phone-number-input/style.css'
import {getCountryCallingCode } from 'react-phone-number-input'
import {COUNTRIES} from "../../helpers/crud-helper/consts";


const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    first_name: Yup.string()
        .required("First name is required"),

    last_name: Yup.string()
        .required("This Field is required"),
    company_name: Yup.string()
        .required("This Field is required"),

    // industries: Yup.string()
    //     .required("This Field is required"),
    message: Yup.string()
        .required("Message is required"),
    country: Yup.string()
        .required("This Field is required"),
    phoneNumber: Yup.number()
        .required("Phone number is required"),

    role: Yup.string().required("This Field is required"),


});

const initialValues = {
    email: "",
    first_name: "",
    message: "",
    phoneNumber :"",
    country : "" ,
    role :"",
    last_name : "",
    company_name : "",
    // industries :""
};

const Contact: FC = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [countries, setCountries] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [countryCode, setCountryCode] = useState<any>('');

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, { setSubmitting }) => {

            setSubmitting(true);
            try {
                setLoading(true);

                const { data } = await contactUs(values);
                //
                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 2500);
                }
            } catch (ex) {

            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    const handleCountryChange = (selectedOption: any) => {
        setCountryCode(getCountryCallingCode(selectedOption.value))
        setSelectedCountry(selectedOption);
    };

    const options = [
        {value: '1', label: t('contact.roles.select.option1')},
        {value: '2', label: t('contact.roles.select.option2')},
        {value: '3', label: t('contact.roles.select.option3')},
        {value: '4', label: t('contact.roles.select.option4')},
        {value: '5', label: t('contact.roles.select.option5')},
        {value: '6', label: t('contact.roles.select.option6')},
        {value: '7', label: t('contact.roles.select.option7')}
    ];

    return (
        <>
            <SocialMeta title={'Demande une demo légales'}/>
            <section className={'bg-light-color position-relative'}>
                <div className={'d-lg-block d-none'}>
                    <div className="bg-equipe1-right"><img src="/assets/images/bg-g-devis.png"  alt={'Spacefit'}/></div>
                    <div className="bg-equipe1-top"><img src="/assets/images/bg-d-devis.png"  alt={'Spacefit'}/></div>
                    <div className="bg-equipe1-bottom"><img src="/assets/images/bg-b-devis.png"  alt={'Spacefit'}/></div>
                </div>


                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 mt-5'}>
                            <div className={'bg-primary-color'}>
                                <h1 className={'main-h1'}>Contact Us</h1>
                            </div>
                        </div>
                    </div>

                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10'}>
                            {isActive && (
                                <div className={``}>
                                    <div className="alert alert-success alert-dismissible fade show  ">
                                        Successfully Done!
                                    </div>
                                </div>
                            )}
                            <form className="form w-100 " onSubmit={formik.handleSubmit} noValidate>
                                <div className={' form-group row '}>
                                    <div className={'col-lg-6 col-6 mt-2 mb-2'}>
                                        <input type={'text'} {...formik.getFieldProps("first_name")} className={clsx(
                                            "form-control main-input",
                                            {"is-invalid": formik.touched.first_name && formik.errors.first_name,},
                                            {"is-valid": formik.touched.first_name && !formik.errors.first_name,}
                                        )} placeholder={t('contact.firstName')}/>
                                    </div>
                                    <div className={'col-lg-6 col-6 mt-2 mb-2'}>

                                        <input type={'text'} {...formik.getFieldProps("last_name")} className={clsx(
                                            "form-control main-input",
                                            {"is-invalid": formik.touched.last_name && formik.errors.last_name,},
                                            {"is-valid": formik.touched.last_name && !formik.errors.last_name,}
                                        )} placeholder={t('contact.lastName')}/>
                                    </div>




                                </div>
                                <div className={' form-group row'}>
                                    <div className={'col-lg-4 col-md-12 mt-2 mb-2 '}>

                                        <input type={'email'}  {...formik.getFieldProps("email")} placeholder={t('contact.companyEmail')} className={clsx("form-control main-input", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                    </div>
                                    <div className={'col-lg-4 col-6 mt-2 mb-2'}>

                                        <input type={'text'}  {...formik.getFieldProps("company_name")}  className={clsx("form-control main-input", {"is-invalid": formik.touched.company_name && formik.errors.company_name,}, {"is-valid": formik.touched.company_name && !formik.errors.company_name})} placeholder={t('contact.company')}/>
                                    </div>





                                    <div className={'col-lg-4 col-6 mt-2 mb-2'}>

                                        {options && options.length > 0 &&
                                            <Select

                                                className={clsx(
                                                    "w-100 main-input",
                                                    {"invalid-select": formik.touched.role && formik.errors.role,},
                                                    {"valid-select": formik.touched.role && !formik.errors.role,}
                                                )}
                                                closeMenuOnSelect={false}
                                                placeholder={t('contact.aboutCompany')}
                                                options={options}
                                                isSearchable={true}
                                                onChange={(newValue: any) =>
                                                    formik.setFieldValue('role', newValue.value)
                                                }
                                            />
                                        }



                                    </div>
                                </div>
                                <div className={' form-group row'}>
                                    {COUNTRIES && COUNTRIES.length > 0 &&
                                        <div className={'col-lg-6 col-6 mt-2 mb-2'}>
                                            <Select

                                                className={clsx(
                                                    "w-100",
                                                    {"invalid-select": formik.touched.country && formik.errors.country},
                                                    {"valid-select": formik.touched.country && !formik.errors.country}
                                                )}
                                                closeMenuOnSelect={true}
                                                placeholder={t('contact.country')}
                                                options={COUNTRIES.map((e: {
                                                    label: any;
                                                    value: any
                                                }) => ({label: e.label, value: e.value}))}
                                                isSearchable={true}
                                                onChange={(newValue: any) => {
                                                    formik.setFieldValue('country', newValue.value);
                                                    handleCountryChange(newValue);
                                                }
                                                }
                                            />



                                        </div>
                                    }


                                    <div className={'col-lg-6 col-6 mt-2 mb-2'}>
                                        <div className="input-group">
                                            {countryCode &&
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text form-control main-input">+{countryCode}</span> {/* Replace XX with your country code */}
                                                </div>
                                            }

                                            <input
                                                type="text"
                                                {...formik.getFieldProps("phoneNumber")}
                                                className={clsx(
                                                    "form-control main-input",
                                                    {"is-invalid": formik.touched.phoneNumber && formik.errors.phoneNumber},
                                                    {"is-valid": formik.touched.phoneNumber && !formik.errors.phoneNumber}
                                                )}
                                                placeholder={t('contact.phoneNumber')}
                                            />
                                        </div>

                                    </div>


                                </div>


                                <div className={'form-group row'}>
                                    <div className={'col-md-12  mt-2 mb-2'}>

                                        <textarea   placeholder={t('contact.description')} {...formik.getFieldProps("message")} className={clsx("form-control main-input  h-185px ", {"is-invalid": formik.touched.message && formik.errors.message,}, {"is-valid": formik.touched.message && !formik.errors.message,})}/>
                                    </div>
                                </div>
                                <div className={'row justify-content-center mt-lg-3 mt-3 mb-5'} >
                                    <div className={'col-lg-3'}>
                                        <button className="btn btn-nav-primary p-btn w-100 " disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                            {!loading && (
                                                <span className="indicator-label">
                               {t('contact.submit')}
                                </span>
                                            )}
                                            {loading && (<span className="indicator-progress" style={{ display: "block" }}><span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>
        </>

    );
};

export default Contact;
