import { useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Header from "./layout/base/Header";
import Footer from "./layout/base/Footer";


const App = () => {
    const location = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    )
}

export {App}
