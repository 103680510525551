import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";


const Footer = () => {
    const {t} = useTranslation();
    const location = useLocation();
    return (
       <>
       {!location.pathname.includes('payment') &&
           <section className="arrival_section bg-activate work-together">
               <div className="container" >
                   <div className="row mt-lg-5 pt-lg-5 pt-3 mt-5 justify-content-center align-items-center w-100">
                       <div className={'col-md-6'}>
                           <div className="arrival_bg_box" >
                               <h3 dangerouslySetInnerHTML={{__html : t('home.ready.title')}} />
                               <p dangerouslySetInnerHTML={{__html: t('home.ready.description')}} />
                           </div>
                       </div>

                       <div className={'col-md-4'}>
                       <div className="" >
                           <div>
                               <Link to={'/demande-demo'} className="btn btn-nav-primary p-btn w-100"
                                    >{t('home.ready.bookDemo')}
                               </Link>
                           </div>
                       </div>
                       </div>
                   </div>
               </div>
           </section>
       }
           {!location.pathname.includes('payment') &&
           <footer>
               <div className="container">
                   <div className="row">
                       <div className="col-md-4">
                           <div className="full">
                               <div className="logo_footer">
                                   <a href="/"><img width="210" src="/assets/images/logos/logo.png" alt="logo"/></a>
                               </div>
                           </div>
                       </div>
                       <div className="col-md-8">
                           <div className="widget_menu float-right">
                               <ul>
                                   <li><Link to="/">{t('navigation.home')}</Link></li>
                                   <li><Link to="/technologie">{t('navigation.technology')}</Link></li>
                                   <li><Link to="/licences">{t('navigation.pricing')}</Link></li>
                                   <li><Link to="/societe">{t('navigation.about')}</Link></li>
                                   <li><Link to="/">{t('navigation.news')}</Link></li>
                                   <li><Link to="/contact">Contact</Link></li>
                               </ul>
                           </div>
                       </div>
                   </div>
               </div>
               <div className={'bdr-btm'}/>

               <div className="cpy_">
                   <div className={'container'}>
                       <div className={'row justify-content-between'}>
                           <div className="col-md-7">
                               <p><span>© 2024 All Rights Reserved By <strong><a
                                   href="http://www.spacefit.ai">Spacefit.ai</a></strong></span></p>
                           </div>
                           <div className="col-md-5">
                               <div className="widget_menu float-right">
                                   <ul>
                                       <li className={'ms-lg-0 ms-4 me-4'}><Link className='text-align-right '
                                                                                 to="/politique-protection-donnees-personnelles">{t('privacy.name')}</Link>
                                       </li>
                                       <li className={'ms-lg-0 ms-4 me-4'}><Link to="/conditions-generale-utilisation">
                                           {t('terms.name')}    </Link></li>
                                       <li className={'ms-lg-0 ms-4 me-2'}><a rel="noreferrer" target={'_blank'}
                                                                              href="https://www.linkedin.com/company/spacefitai/"><img
                                           src="/assets/images/linkedin.svg"
                                           alt="linkedin"/></a></li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>


               </div>
           </footer>
           }


       </>
    )
}
export default Footer;
