import React, {useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

const languages_2 = [
    { value: 'en',  label: "English" , flag: '/assets/images/flags/uk.png'},
    { value: 'fr',   label: "Français" , flag: '/assets/images/flags/france.png'},

];

interface OptionType {
    label: string;
    value: string;
    flag ?:any
}
const Header = () => {
    const {t} = useTranslation();
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const location = useLocation();
    const currentRoute = location.pathname;
    const initialSelectedOption = languages_2.find(
        (language) => language.value === currentLanguageCode
    );
    const [showDropdown, setShowDropdown] = useState(false);

    const [selectedOption, setSelectedOption] = useState<OptionType | null>(
        initialSelectedOption || null
    )
    const toggleDropdown = () => {
        setShowDropdown(prevState => !prevState);
    };
    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };


    return (
        <>

            <Navbar expand="xl" className="bg-white">
                <Container >
                    <Navbar.Brand>
                        <Link className={''} to="/">
                        <img src={"/assets/images/logos/logo.png"} width="250" alt="SpaceFit" className="logo"/>
                    </Link>
                    </Navbar.Brand>
                    {!location.pathname.includes('payment') &&
                    <Navbar.Toggle onClick={toggleNavbar} aria-controls="navbarScroll">
                        {isNavbarExpanded ? (
                            <i className="bi bi-x" /> // Use Bootstrap icons or your own 'X' icon
                        ) : (
                            <i className="bi bi-list" /> // Use Bootstrap icons or your own hamburger icon
                        )}
                    </Navbar.Toggle>
                    }
                    {!location.pathname.includes('payment') &&
                    <Navbar.Collapse className={'container justify-content-center'} id="navbarScroll">
                        <Nav className=" align-items-center" navbarScroll>
                            <NavLink className={`nav-link ${location.pathname.includes('/') && !location.pathname.includes('#') && !location.pathname.includes('/technologie') && !location.pathname.includes('/licences') && !location.pathname.includes('conditions-generale-utilisation') && !location.pathname.includes('/societe') && 'active'}`}  to="/">{t('navigation.home')}</NavLink>
                            <NavLink className={`nav-link ${location.pathname.includes('/technologie') && 'active'}`}  to="/technologie">{t('navigation.technology')}</NavLink>
                            <NavLink className={`nav-link ${location.pathname.includes('/licences') && 'active'}`}  to="/licences">{t('navigation.pricing')}</NavLink>
                            <NavLink className={`nav-link ${location.pathname.includes('/societe') && 'active'}`}  to="/societe">{t('navigation.about')}</NavLink>
                            <Link className={'nav-link menu-disabled'}  to="#">{t('navigation.news')}</Link>
                            <div className="dropdown">
                                <button
                                    className="btn btn-link dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={toggleDropdown}
                                >
                                   <span ><img alt={'Spacefit'} className={'me-1'} src={selectedOption?.flag} /> <span className={'pt-1'}>{selectedOption?.label}</span> </span>
                                </button>
                                {showDropdown && (
                            <ul className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton1">
                                {languages_2.map(({ value, label, flag }) => (
                                    <li key={value}>
                                        <a href={`/${value}${currentRoute.replace(/^\/(fr|en)\//, '/')}`} className={'dropdown-item fs-14'}>
                                            {label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                                    )}
                            </div>

                        </Nav>

                        <div className="d-xl-flex text-center">
                            <div className={'pt-xl-0 pt-3'}>
                                <NavLink target={'_blank'} className={'btn btn-black'}  to="http://app.spacefit.ai/auth/sign-in"> {t('navigation.connexion')} </NavLink>

                            </div>
                            <div className={'pt-xl-0 pt-3'}>
                                <NavLink className={`btn btn-nav-primary ${location.pathname.includes('/demande-demo') && 'active'}`}  to="/demande-demo"> {t('navigation.connector')}  </NavLink>

                            </div>
                        </div>
                    </Navbar.Collapse>
                    }
                </Container>
            </Navbar>
        </>
    )
}
export default Header;
